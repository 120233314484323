import Category from '../models/Category';
import SubCategory from '../models/SubCategory';
import Product from '../models/Product';
import Usage from '../models/Usage';
import Model from '../models/Model';

//usages
const office = "bureau";
const education = "Education";
const house ="Maison"
const health = "Santé";
const sports = "Sport";
const commerce = "Commerce";
const storage = "Industrie & Stockage"
//Products


//Prodcuts of concept Neo
const centro = new Product(1, 'centro', './images/models/concept-neo/centro/centro.png', 'Always aesthetic, always trendy! Catch fashion and innovation for many years with its impeccable decor.', [office, house]);
const moderna = new Product(2, 'moderna', './images/models/concept-neo/centro/centro.png', 'Always aesthetic, always trendy! Catch fashion and innovation for many years with its impeccable decor.', [office, house]);
const scala = new Product(3, 'scala', './images/models/concept-neo/centro/centro.png', 'Always aesthetic, always trendy! Catch fashion and innovation for many years with its impeccable decor.', [office, house]);
const portello = new Product(4, 'portello', './images/models/concept-neo/centro/centro.png', 'Always aesthetic, always trendy! Catch fashion and innovation for many years with its impeccable decor.', [office, house]);
const lima = new Product(5, 'lima', './images/models/concept-neo/centro/centro.png', 'Always aesthetic, always trendy! Catch fashion and innovation for many years with its impeccable decor.', [office, house]);

//
const conceptNeo = new Model(1, "Concept Neo", "./images/models/concept-neo/concept-neo.png",[centro] )
const effect = new Model(2, "Effect", "./images/models/effect/effect.webp",[centro] )
const natureLine = new Model(3, "Nature Line", "./images/models/nature-line/nature-line.webp",[centro] )
const pruuva = new Model(4, "Pruva", "./images/models/pruuva/pruva.webp",[centro] )
const bella = new Model(5, "Bella", "./images/models/bella/bella.png",[centro] )

//parquet
const stratifié = new SubCategory(1, 'stratifié', [conceptNeo, effect, natureLine, pruuva, bella]);
const semiMassif = new SubCategory(2, 'semi massif', []);
const massif = new SubCategory(3, 'massif', []);

//gerflex
const residentiel = new SubCategory(4, 'residentiel', []);
const moyenTrafic = new SubCategory(5, 'moyen trafic', []);
const grandTrafic = new SubCategory(6, 'grand trafic', []);
const lame = new SubCategory(7, 'lame pcv - spc', []);

//gazon artificiel
const decoratif = new SubCategory(8, 'decoratif', []);
const jardin = new SubCategory(9, 'jardin', []);
const sport = new SubCategory(10, 'sport', []);
const grillage = new SubCategory(11, 'grillage', []);

//tapis paillasson
const spagheti = new SubCategory(12, 'spagheti', []);
const zemat = new SubCategory(13, 'zemat', []);
const honney = new SubCategory(14, 'honney', []);
const aspro = new SubCategory(15, 'aspro', []);

//bois exterieur
const bambou = new SubCategory(16, 'bambou', []);
const teak = new SubCategory(17, 'teak', []);
const ipe = new SubCategory(18, 'ipe', []);
const composite = new SubCategory(19, 'composite', []);

//bardage habillage
const mdf = new SubCategory(20, 'mdf', []);
const spc = new SubCategory(21, 'spc', []);
const wpc = new SubCategory(22, 'wpc', []);
const liége = new SubCategory(23, 'liége', []);
const papierPeint = new SubCategory(24, 'papier peint', []);


const parquet = new Category(1, 'parquet', [stratifié, semiMassif, massif]);
const gerflex = new Category(2, 'gerflex (vinyle)', [residentiel, moyenTrafic, grandTrafic, lame]);
const gazonArtificiel = new Category(3, 'gazon artificiel', [decoratif, jardin, sport, grillage]);
const tapisPaillasson = new Category(4, 'tapis paillasson', [spagheti, zemat, honney, aspro]);
const boisExterieur = new Category(5, 'bois extérieur', [bambou, teak, ipe, composite]);
const bardage = new Category(6, 'bardage - habillage', [papierPeint, liége,wpc, spc, mdf]);
const placoPlatre = new Category(7, 'placo platre', [papierPeint, liége, wpc]);


const categories = [parquet, gerflex, gazonArtificiel, tapisPaillasson, boisExterieur, bardage, placoPlatre];

export default categories;