import { Link } from 'react-router-dom';
import './Decor.css';

export default function Decor({product}) {
    return (
        <Link key={product.id} className='decor' to={`/DecorDetails/${product.id}`}>
                <img src={product.image} />
                <div>
                    <header>{product.name}</header>
                    <span>{product.code}</span>
                </div>
                <img src={product.ambianceImage} />
        </Link>
    );

}