import './DecorDetails.css';
import products from '../../../../../../data/products';
import { useParams } from 'react-router-dom';
import Title from '../../../../../Title/Title';

export default function DecorDetails() {

    const { id } = useParams();
    const decor = products.find((p) => p.id === parseInt(id));

    return (
        <div className='decor-details'>
            <div>
                <img src={decor.ambianceImage} />
                <div>
                    <header>{decor.name} - {decor.code}</header>
                    <p>{decor.description}</p>
                    <Title content={"Context d'utilisation"} />
                    <div>
                        {/*decor.usages.map((str, index) => (
                            <span key={index}>{str}</span>
                        ))*/}
                        <span>Bureaux</span>
                        <span>Maison</span>
                    </div>
                </div>

            </div>
            <div>
                <div>
                    <Title content={'Spécification techniques'} />
                    <div>
                        <div>
                            <span>Longeur :</span>
                            <span>1195 mm</span>
                        </div>
                        <div>
                            <span>Largeur :</span>
                            <span>154,5 mm</span>
                        </div>
                        <div>
                            <span>épaisseur :</span>
                            <span>10 mm</span>
                        </div>
                        <div>
                            <span>Surface :</span>
                            <span>Chêne huilé</span>
                        </div>

                    </div>
                </div>
                <img src={decor.image} />
            </div>
        </div>
    );
}