import './SideNavbar.css';
import { useEffect, useState } from 'react';

export default function SideNavbar({ categories, selectedCategory, onCategorySelect, onSubCategorySelect }) {
    const [expandedCategoryId, setExpandedCategoryId] = useState(null);

    const toggleCategory = (category) => {
        setExpandedCategoryId(expandedCategoryId === category.id ? null : category.id);
        onCategorySelect(category);
    };

    const [isVisible, setIsVisible] = useState(false); // Animation trigger
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarVisible(!isSidebarVisible);
    };

    useEffect(() => {
        // Trigger the animation when the component mounts
        setIsVisible(true);
    }, []);
    


    return (
        <>
         <div className={`side-navbar ${isSidebarVisible ? 'visible' : 'hidden'} ${isVisible ? 'slide-in' : ''}`}>
            {categories.map((category) => (
                <div key={category.id}>
                    <div onClick={() => toggleCategory(category)} className={selectedCategory?.id === category.id ? "selected-category" : ""}>
                        <img src="./icons/triangle.png" alt="Toggle"className={expandedCategoryId === category.id ? "rotated" : ""} />
                        <header>{category.name}</header>
                    </div>
                    {expandedCategoryId === category.id && (
                        <div className={`subcategories ${expandedCategoryId === category.id ? "expanded" : ""}`}>
                            {category.subCategories.map((subCategory) => (
                                <span key={subCategory.id} onClick={() => onSubCategorySelect(subCategory)}>
                                    - {subCategory.name}
                                </span>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </div>
        <div className={`arrow ${isSidebarVisible ? 'rotated-arrow' : ''}`} onClick={toggleSidebar}>
            <img src='./icons/arrow.png'/>
        </div>
        </>
       
    );
}