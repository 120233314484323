import { Link } from 'react-router-dom';
import './Model.css';

export default function Model({model}) {
    return (
            <Link key={model.id} to={`/decors/${model.id}`}>
                <div className="product" >
                    <div>
                        <img src={model.image} />
                        <header>{model.name}</header>
                    </div>
                </div>
            </Link>

    );
}