import { Link } from "react-router-dom";
import "./UnderConstruction.css";

export default function UnderContruction() {

    return (
        <>
            <div className="main">
                <div>
                    <div>
                        <img src="./images/logos/logo.png" />
                        <span>Cette page est en cours de maintenance</span>
                        <Link className ="home-link" to="/">Retour à la page d'Accueil</Link>
                    </div>
                    <div>
                        <img src="./images/products/parquet.jpg" />
                    </div>
                </div>
            </div>
        </>
    );
}