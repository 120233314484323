import './Footer.css'

export default function Footer(){
    return(
        <div className='footer'>
                <div>
                    <img src='../images/logos/logo-fn.png' />
                    <div>
                        <img src='../icons/email.png' />
                        <span>decopa@decopa.ma</span>
                    </div>
                    <div>
                        <img src='../icons/position.png' />
                        <a href="https://www.google.com/maps?ll=34.026615,-4.993632&z=16&t=m&hl=fr&gl=MA&mapclient=embed&cid=11072775408263032613">Showroom parquet & papier-peint
                            <br />1 Rue Abed El Fassi, Avenue Ibn Khatib
                            30060 - Fès, Maroc /
                        </a>
                    </div>
                    <div>
                        <img src='../icons/phone.png' />
                        <span>+212(0)5 35 65 96 08</span>
                    </div>
                    <div>
                        <img src='../icons/position.png' />
                        <a href="https://www.google.com/maps?ll=34.026615,-4.993632&z=16&t=m&hl=fr&gl=MA&mapclient=embed&cid=11072775408263032613">Dépôt/Showroom gerflex & placo-platre
                            <br />Résidence Anas 2, Ait Skato II
                            30060 - Fès, Maroc /
                        </a>
                    </div>
                    <div>
                        <img src='../icons/phone.png' />
                        <span>+212(0)5 35 65 61 75</span>
                    </div>
                </div>

                <div>
                    <header>NEWSLETTER</header>
                    <p>Rejoignez notre liste de diffusion pour des conseils et des informations utiles.</p>
                    <input type='email' placeholder='ENTREZ VOTRE EMAIL' />
                    <span>S'Abonner</span>
                </div>
                <div>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3306.672387195745!2d-4.996206925450378!3d34.0266194190848!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd9f8b6bb6346d69%3A0x99aa66915732ef25!2sDECOPA!5e0!3m2!1sfr!2sma!4v1730369994599!5m2!1sfr!2sma" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
    );
}