
import Model from "../models/Model";
import Product from "../models/Product";
import products from "./products";


const office = "bureau";
const education = "Education";
const house ="Maison"
const health = "Santé";
const sports = "Sport";
const commerce = "Commerce";
const storage = "Industrie & Stockage"

const centro = new Product(1, 'centro', '../images/models/concept-neo/centro/centro.png', '../images/models/concept-neo/centro/ambiance-centro.png','PRK601','Always aesthetic, always trendy! Catch fashion and innovation for many years with its impeccable decor.');
const moderna = new Product(2, 'moderna', '../images/models/concept-neo/moderna/moderna.png','../images/models/concept-neo/moderna/ambiance-moderna.png','PRK604', 'Always aesthetic, always trendy! Catch fashion and innovation for many years with its impeccable decor.', [office, house]);
const scala = new Product(3, 'scala', '../images/models/concept-neo/scala/scala.png','../images/models/concept-neo/scala/ambiance-scala.png','PRK603', 'Always aesthetic, always trendy! Catch fashion and innovation for many years with its impeccable decor.');
const portello = new Product(4, 'portello', '../images/models/concept-neo/portello/portello.webp','../images/models/concept-neo/portello/ambiance-portello.webp','PRK608', 'Always aesthetic, always trendy! Catch fashion and innovation for many years with its impeccable decor.');
const lima = new Product(5, 'lima', '../images/models/concept-neo/lima/lima.png','../images/models/concept-neo/lima/ambiance-lima.png','PRK606', 'Always aesthetic, always trendy! Catch fashion and innovation for many years with its impeccable decor.');

const models = [
    new Model(1, "Concept Neo", "./images/models/concept-neo/concept-neo.png", [moderna, portello, scala, lima, centro ]),
    new Model(2, "Effect", "./images/models/effect/effect.png", []),
    new Model(3, "Nature Line", "./images/models/nature-line/nature-line.png", []),
    new Model(4, "Pruva", "./images/models/pruuva/pruuva.png", []),
    new Model(5, "Bella", "./images/models/bella/bella.png", []),
]

export default models;