class Product{
    constructor(id, name, image, ambianceImage, code, description, performances, usages) {
        this.id = id;
        this.name = name;
        this.image = image;
        this.ambianceImage = ambianceImage;
        this.code = code;
        this.description = description;
        this.performances = performances;
        this.usages = usages;
      }
}

export default Product;