import './Title.css';

export default function Title({content}){
    return(
        <>
        <div className='title'>
            <hr/><header>{content}</header><hr/>
        </div>
        </>
    )
}