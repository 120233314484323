import Product from "../models/Product";

const office = "bureau";
const education = "Education";
const house ="Maison"
const health = "Santé";
const sports = "Sport";
const commerce = "Commerce";
const storage = "Industrie & Stockage"

const products = [
     new Product(1, 'centro', '../images/models/concept-neo/centro/centro.png', '../images/models/concept-neo/centro/ambiance-centro.png','PRK601','Always aesthetic, always trendy! Catch fashion and innovation for many years with its impeccable decor.', [office, house]),
     new Product(2, 'moderna', '../images/models/concept-neo/moderna/moderna.png','../images/models/concept-neo/moderna/ambiance-moderna.png','PRK604', "Toujours esthétique, toujours tendance ! Attrapez la mode et l'innovation depuis de nombreuses années avec sa décoration impeccable..", [office, house]),
     new Product(3, 'scala', '../images/models/concept-neo/scala/scala.png','../images/models/concept-neo/scala/ambiance-scala.png','PRK603', 'Always aesthetic, always trendy! Catch fashion and innovation for many years with its impeccable decor.', [office, house]),
     new Product(4, 'portello', '../images/models/concept-neo/portello/portello.webp','../images/models/concept-neo/portello/ambiance-portello.webp','PRK608', 'Always aesthetic, always trendy! Catch fashion and innovation for many years with its impeccable decor.', [office, house]),
     new Product(5, 'lima', '../images/models/concept-neo/lima/lima.png','../images/models/concept-neo/lima/ambiance-lima.png','PRK606', 'Always aesthetic, always trendy! Catch fashion and innovation for many years with its impeccable decor.', [office, house]),
];

export default products;
