import SubCategory from "./SubCategory";

class Category{
    constructor(id, name, subCategories = []) {
        this.id = id;
        this.name = name;
        this.subCategories = subCategories;
      }

      addSubCategory(subCategory) {
        this.subCategories.push(subCategory);
      }
}

export default Category;