import './Products.css';
import ProductsList from './ProductsList/ProductsList';
import SideNavbar from './SideNavbar/SideNavbar';
import categories from '../../data/Categories';
import { useState } from 'react';

export default function Products(){

    const [selectedCategory, setSelectedCategory] = useState(categories[0]);
    const [selectedSubCategory, setSelectedSubCategory] = useState(categories[0].subCategories[0]);

  // Get the products based on the selected category and subcategory
  const filteredProducts = selectedSubCategory
    ? selectedSubCategory.models
    : selectedCategory
    ? selectedCategory.subCategories.flatMap(sub => sub.models)
    : [];

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setSelectedSubCategory(null); // Reset subcategory when category changes
  };

  const handleSubCategorySelect = (subCategory) => {
    setSelectedSubCategory(subCategory);
  };

    return(
        <>
        <div className='products'>
            <SideNavbar categories={categories} onCategorySelect={handleCategorySelect} onSubCategorySelect={handleSubCategorySelect}/>
            <ProductsList models={filteredProducts}/>
        </div>
        </>
    );
}