import Model from '../Product/Model';
import './ProductsList.css';
import products from '../../../data/products';

export default function ProductsList({models}) {

    return (
        <>
            <div className="products-list" >
                {models.map((model) => (
                    <Model key={model.id} model={model} />
                ))}
            </div>
        </>
    );
}