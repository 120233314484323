import './Decors.css';

import models from '../../../../data/Models';
import { useParams } from 'react-router-dom';
import Decor from './Decor/Decor';

export default function Decors() {

    const { id } = useParams();
    const model = models.find((p) => p.id === parseInt(id));

    return (
        <div className='decors'>
            {model.products.map((product) => (
                    <Decor key={product.id} product={product} />
                ))}
        </div>
    );
}