import React from "react";
import Slider from "react-slick";
import './LogoSlider.css'

const LogoSlider = ({ logos, nbrImages, imageSize="60%", imageParentSize="70%" }) => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: nbrImages,  
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <Slider {...settings} className="logo-slider" style={{ width: imageParentSize}} >
      {logos.map((logo, index) => (
        <div key={index} >
          <img src={logo} alt={`logo-${index}`} style={{ width: imageSize}} />
        </div>
      ))}
    </Slider>
  );
};

export default LogoSlider;